import $ from "jquery";

function scrollToAnchor(anchor) {
  const headerHeight = $(".header").height() || 0;
  const scrollDistance = $(anchor).offset().top - headerHeight;

  $("html, body").animate(
    {
      scrollTop: scrollDistance,
    },
    500
  );
}

$(function () {
  if (window.location.hash && /^#[a-z0-9.:_-]+$/i.test(window.location.hash)) {
    const anchor = window.location.hash;

    if ($(anchor).length) {
      scrollToAnchor(anchor);
    }
  }
});

// Add the Scroll to Anchor function to jQuery
$.scrollToAnchor = scrollToAnchor;
