import $ from "jquery";

function initializeAnySeriesSlider() {
  const $slider = $("section#series .slider");
  if ($slider.length === 0) {
    return;
  }

  const cntWidth = $slider.innerWidth();
  $(window).trigger("ljComponentsInit", ["slider", $slider, cntWidth]);
}

export function requirePageJs(pageType) {
  switch (pageType) {
    case "account":
      require("./account.js");
      break;
    case "administration":
      require("./administration.js");
      break;
    case "episode":
      require("./episode.js");
      break;
    case "episode-preview":
      require("./episode.js");
      require("./preview.js");
      break;
    case "home":
      require("./home.js");
      break;
    case "mes-jours":
      require("./mes-jours.js");
      break;
    case "obsessions":
      require("./obsessions.js");
      break;
    case "obsession":
      require("./obsession.js");
      break;
    case "openhouse":
      require("./openhouse.js");
      break;
    case "podcast":
      require("./podcast.js");
      break;
    case "podcast-preview":
      require("./podcast.js");
      break;
    case "programme":
      require("./programme.js");
      break;
    case "subscription":
      require("./subscription.js");
      break;
    case "unsubscribe":
      require("./desabonnement.js");
      break;
    default:
      initializeAnySeriesSlider();
      break;
  }
}
