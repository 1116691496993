import { GDPRConsent } from "gdpr-consent";
import $ from "jquery";
import { SubscribeWithGoogleOptions } from "../config";

function sleep(waitTimeInMs) {
  return new Promise((resolve) => {
    setTimeout(resolve, waitTimeInMs);
  });
}

async function checkAccountForEntitlements(entitlements) {
  let waitTime = 1;
  const maxWaitTime = 10;
  for (let i = 0; i < 8; i++) {
    // Check if we have an account for these entitlements
    let response = await $.post("/mon-compte/entitlements", { swg_entitlements: entitlements.raw });
    if (typeof response?.email === "string" && typeof response?.token === "string") {
      // The given entitlements have been processed
      return response;
    }

    // Keep waiting for the entitlements
    await sleep(waitTime * 1000);
    waitTime = Math.min(waitTime * 2, maxWaitTime);
  }
}

function logTheUser(email, token) {
  return $.post("/session", { email: email, token: token });
}

function accountLoginFLow(subscriptions, entitlements) {
  // Entitlements grant access to the product linked to this content.
  // Look up the user. Resolve the promise with an account.
  const accountPromise = checkAccountForEntitlements(entitlements);

  // Notify the user that their account is being looked up. It will resolve
  // when accountPromise resolves.
  subscriptions.waitForSubscriptionLookup(accountPromise).then(async (account) => {
    if (typeof account?.email === "string" && typeof account?.token === "string") {
      // Account was found.
      // Notify the user that they're being automatically signed-in.
      await subscriptions.showLoginNotification();

      // Log the user.
      await logTheUser(account.email, account.token);

      // Check if we are on the subscription page
      if (window.location.pathname.startsWith("/abonnement/")) {
        // Redirect the user to the account page
        window.location.href = "/mon-compte/";
      } else {
        // Reload the page
        window.location.reload();
      }
    } else {
      // Redirect the user to the login page
      window.location.href = "/connexion";
    }
  });
}

let subscribeWithGoogleLoaded = false;
$.loadSubscribeWithGoogle = function () {
  if (subscribeWithGoogleLoaded === true) {
    return;
  }

  subscribeWithGoogleLoaded = true;
  (self.SWG = self.SWG || []).push(function (subscriptions) {
    // Specifing the paySwgVersion backend parameter is required when
    // manually initializing swg.js for RRM:E
    subscriptions.configure({ paySwgVersion: "2" });

    // Either use a publication ID (example.com) or
    // a full product ID (example.com:premium).
    subscriptions.init(SubscribeWithGoogleOptions.productId);

    $.linkSubscriptionWithGoogle = function () {
      $.post(`/mon-compte/manage-swg-subscription-linking`, { action: "get-ppid" })
        .then(({ publisherProvidedId }) => {
          return subscriptions.linkSubscription({ publisherProvidedId: publisherProvidedId });
        })
        .then((result) => {
          console.log("Subscription linking result : " + JSON.stringify(result));

          return $.post(`/mon-compte/manage-swg-subscription-linking`, { action: "refresh-link" });
        })
        .then(() => {
          window.location.reload(); // Reload the page
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (tiggerSubscriptionLinkingAfterLoad) {
      $.linkSubscriptionWithGoogle();
    }

    // Attach SwG smart buttons
    $(".subscribe-with-google-btn").each(function (idx, buttonElement) {
      buttonElement.innerHTML = "";
      subscriptions.attachButton(buttonElement, { lang: "fr" }, function () {
        let swgSku = $(buttonElement).data("swgSku");
        subscriptions.subscribe(swgSku);
      });
    });

    // Handles user clicking "Already Subscribed?"
    subscriptions.setOnLoginRequest(() => {
      // Redirects user to publisher login page
      window.location.href = "/connexion";
    });

    // Handle purchases
    subscriptions.setOnPaymentResponse(async (paymentResponse) => {
      // 1. Wait for a successful payment response.
      // This promise resolves after the payments window is closed.
      const response = await paymentResponse;

      // 2. Handle the payment response.
      // Nothing to do, we handle purchases with Pub/Sub

      // 3. Once the payment is processed, complete the transaction.
      // This can fire only after the subscriber has dismissed the
      // payment completion screen.
      await response.complete();

      // 4. Launch a publisher-specific account creation flow.
      accountLoginFLow(subscriptions, response.entitlements);
    });
  });
};

let tiggerSubscriptionLinkingAfterLoad = false;
$.tiggerSubscriptionLinkingAfterLoad = function () {
  tiggerSubscriptionLinkingAfterLoad = true;
};

// Check if the service is allowed
if (!GDPRConsent.isActivated("subscribewithgoogle")) {
  $(".subscribe-with-google-btn").on("click", function () {
    // Allow the service with the GDPR consent
    GDPRConsent.activate("subscribewithgoogle");
  });
}
